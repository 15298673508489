import React from "react";
import Head from "./head";
import Header from "./header";
import Hero from "./hero";
import Footer from "./footer";
import "../../styles/index.scss";
import * as styles from "./layout.module.scss";
import backgroundImage from "..//../images/warrior.jpg";

const Layout = ({ children, title }) => {
    return (
        // <div
        // // className={styles.container}
        // // style={{ backgroundImage: `url(${backgroundImage})` }}
        // >
        <div className={styles.content}>
            <Head title={title} />
            <Header />
            <Hero />
            <h1 className={styles.title}>{title}</h1>
            <div className={`${styles.body} max-w-screen-xl m-auto mt-12`}>
                {children}
            </div>
        </div>

        //{/* sticky footer */}
        // {/* <Footer /> */}
        //   {/* </div> */}
    );
};

export default Layout;
