// Handles the head of our documents

import React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import * as headStyles from "../layout/head.module.scss";

const Head = props => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <div>
            <div className={headStyles.helmet}>
                <Helmet
                    className={headStyles.helmet}
                    title={`${props.title} | ${data.site.siteMetadata.title}`}
                />
            </div>
        </div>
    );
};

export default Head;
