import React from "react";
import { Link } from "gatsby";

import * as styles from "./nav.module.scss";

const Nav = () => {
    return (
        <div id="navigation" className={styles.navigation}>
            <nav>
                <ul className={styles.navList}>
                    <li>
                        <Link className={styles.navItem} to="/">
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link className={styles.navItem} to="/food-menu">
                            DL's Menu
                        </Link>
                    </li>
                    <li>
                        <Link className={styles.navItem} to="/">
                            CV
                        </Link>
                    </li>
                    <li>
                        <Link className={styles.navItem} to="/contact">
                            Contact
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Nav;
