import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";

import * as styles from "./header.module.scss"; //using stylesheet components
import Nav from "./nav";
import logoImage from "../../images/dan-logo-white.png";

const Header = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <header className={styles.header}>
            <div id="logo" className={styles.logo}>
                <Link to="/">
                    <img
                        src={logoImage}
                        width="75"
                        height="75"
                        alt="sloth"
                        color="white"
                    />{" "}
                </Link>
            </div>
            <Nav className={styles.navigation} />
            {/* <div className={styles.userProfile}>
                <div className={styles.user}>
                    <div className="name"> {data.site.siteMetadata.title}</div>
                    <div className="image">
                        <img
                            src={logoImage}
                            width="75"
                            height="75"
                            alt="sloth"
                        />
                    </div>
                </div>
            </div> */}
        </header>
    );
};

export default Header;
