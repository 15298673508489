import React from "react";
// import { Link } from "gatsby"; // preload links
import testImage from "../images/whale-illusion.jpg";

import Layout from "../components/layout/layout";

const Index = () => {
    return (
        <Layout title="">
            {/* <img src={testImage} width="800" height="600" alt="Ultimate Aqua" /> */}
            <grid></grid>
        </Layout>
    );
};

export default Index;
