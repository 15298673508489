import React from "react";
import * as styles from "./hero.module.scss";
import backgroundImage from "../../images/financial-freedom.jpg";

const Hero = () => {
    return (
        <div>
            <div className={styles.hero}>
                <div className={styles.content}>
                    <h2>Financial freedom</h2>
                    <p>
                        Here is my story on how I managed to accumulate enough
                        wealth to pay off my student loans before I graduated
                        from university, and how I was able to then go on to
                        retire and become financially free by the age of 24.
                    </p>
                    <div className={styles.buttonWrapper}>
                        <a href="#" className={styles.buttonEl}>
                            Read now
                        </a>
                        {/* <a href="#" className={styles.buttonEl}>
                            My list
                        </a> */}
                    </div>
                </div>
                <div className={styles.content2}>
                    <h2>Rich or wealthy?</h2>
                    <p>
                        The rich have money; the wealthy have time. Break free,
                        be both. Anything is possible if you're earning while
                        you're sleeping.
                    </p>
                    <div className={styles.buttonWrapper}>
                        {/* <a href="#" className={styles.buttonEl}>
                            Watch now
                        </a> */}
                        <a href="#" className={styles.buttonEl}>
                            Passive income list
                        </a>
                    </div>
                </div>
                <div
                    className={styles.overlay}
                    style={{ background: `url(${backgroundImage})`, backgroundSize: 'cover'}}
                ></div>
            </div>
            {/* <div className={styles.hero}>
                <div className={styles.content}>
                    <h2>Season 2 now available</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Doloremque id quam sapiente unde voluptatum alias
                        vero debitis, magnam quis quod.
                    </p>
                    <div className={styles.buttonWrapper}>
                        <a href="#" className={styles.buttonEl}>
                            Watch now
                        </a>
                        <a href="#" className={styles.buttonEl}>
                            My list
                        </a>
                    </div>
                </div>
                <div
                    className={styles.overlay}
                    style={{ background: `url(${backgroundImage})` }}
                ></div>
            </div> */}
        </div>
    );
};

export default Hero;
